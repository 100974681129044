import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import ReferidosHeader from "./referidos/index";
import Container from "./styled";
import { Link } from "react-router-dom";

export default function Header({
  isReferidosHeaderOpen,
  toggleReferidosHeader,
}) {
  return (
    <Container name="header">
      {isReferidosHeaderOpen == true && (
        <ReferidosHeader
          toggleReferidosHeader={toggleReferidosHeader}
        ></ReferidosHeader>
      )}
      <div id="header-container">
        <div id="logo-data-container">
          {/* Logo */}
          <h1>
            <Link to="/">
              <img alt="Logo de SeguroMotos" src="/logos/seguromotos.svg"></img>
            </Link>
          </h1>
          {/* Numeros de telefono y redes Container */}
          <div id="numeros-redes-container">
            {/* Números de telefono */}
            <a className="boton-telefono" href="tel:08102202373">
              0810-220-2373
            </a>
            <a className="boton-telefono" href="tel:1152632373">
              (011) 5263-2373
            </a>
            {/* Redes */}
            <div id="redes">
              {/* Instagram */}
              <a href="https://www.instagram.com/seguromotos/" target="_blank">
                <BsInstagram />
              </a>
              {/* Facebook */}
              <a
                href="https://www.facebook.com/SeguroMotosOficial/"
                target="_blank"
              >
                <FaFacebookF />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
